const autocompleteInputs = document.querySelectorAll(".input-autocompeletion");

if (autocompleteInputs.length) {
  autocompleteInputs.forEach(function (autocompleteInput) {
    const autocompeletionUrl = autocompleteInput.dataset.urlAutocompeletion;

    if (autocompeletionUrl) {
      import("awesomplete").then((module) => {
        const Awesomplete = module.default;
        const ajax = new XMLHttpRequest();
        ajax.open("GET", autocompeletionUrl, true);
        ajax.onload = function () {
          const list = JSON.parse(ajax.responseText).map(function (res) {
            return res.nameProduct;
          });
          const awesomplete = new Awesomplete(autocompleteInput, {
            list: list,
            minChars: 3,
            maxItems: 5,
            item: (text) => {
              const listItem = Awesomplete.$.create("li", {
                innerHTML: `<strong>${text.substr(0, 3)}</strong>${text.substr(3)}`,
              });
              return listItem;
            },
          });
          awesomplete.input.addEventListener("awesomplete-open", function () {
            autocompleteInput.classList.add("awesomplete-list-open");
          });
          awesomplete.input.addEventListener("awesomplete-close", function () {
            autocompleteInput.classList.remove("awesomplete-list-open");
          });
        };
        ajax.send();
      });
    }
  });
}
