const $dateField = document.querySelector(".date-field");

if ($dateField) {
  const localePaths = {
    fr: require("vanillajs-datepicker/locales/fr"),
    en: require("vanillajs-datepicker/locales/en-AU"),
    // Add more locale paths as needed
  };

  const websiteLang =
    document.documentElement.lang.trim().toLowerCase() || "fr";
  const localeModule = localePaths[websiteLang];

  import("vanillajs-datepicker").then(({ Datepicker }) => {
    Object.assign(Datepicker.locales, localeModule.default);
    new Datepicker($dateField, {
      autohide: true,
      locale: localeModule.default,
      language: websiteLang,
    });
  });
}
