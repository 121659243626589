const form = document.querySelector(".block-form-builder form");

if (form) {
  import("pristinejs/dist/pristine").then((module) => {
    const Pristine = module.default;
    const pristine = new Pristine(form);

    form.addEventListener("submit", function (e) {
      e.preventDefault();

      // check if the form is valid
      pristine.validate();

      if (pristine.validate()) {
        form.submit();
      } else {
        const hasDanger = document.querySelector(".has-danger");

        if (hasDanger) {
          window.scrollTo({
            top: document.querySelector(".has-danger").offsetTop,
            behavior: "smooth",
          });
        }
      }
    });
  });

  //bs custom file input

  import("bs-custom-file-input").then((module) => {
    const bsCustomFileInput = module.default;
    bsCustomFileInput.init();
  });
}
