const elementsWithDataToShow = document.querySelectorAll(".has-data-to-show");
const form = document.querySelector(".js-validate-form");
let newPristine = null;

function initPristine(form, Pristine) {
  if (newPristine) {
    newPristine.destroy();
  }
  newPristine = new Pristine(form);

  form.addEventListener("submit", function (e) {
    // check if the form is valid
    newPristine.validate();
    // add dynamic csrf toket
    const csrf_token = document.querySelector(
      "input[name*=_token][type=hidden]"
    );
    if (csrf_token) {
      const token = document.querySelector('meta[name="CSRF-Token"]')?.content;
      if (token) {
        csrf_token.setAttribute("value", token);
      }
    }
    if (newPristine.validate()) {
      const validateMsg = document.querySelector(".show-validate-msg");

      if (form.classList.contains("has-validate-msg") && validateMsg) {
        form.classList.add("d-none");
        validateMsg.classList.remove("d-none");
      }
    } else {
      e.preventDefault();
      console.log(newPristine.validate());
      console.log(newPristine.getErrors());

      const hasDanger = document.querySelector(".has-danger");

      if (hasDanger) {
        window.scrollTo({
          top: document.querySelector(".has-danger").offsetTop,
          behavior: "smooth",
        });
      }
    }
  });
}

function initRecaptcha() {
  let recaptcha_script = document.querySelector(
    '.modal script[data-name="recaptcha"]'
  );

  if (recaptcha_script) {
    let sc = document.createElement("script");
    let recaptcha_key = recaptcha_script.getAttribute("data-key");
    sc.setAttribute("src", recaptcha_script.getAttribute("src"));
    sc.setAttribute("type", "text/javascript");
    document.head.appendChild(sc);
    window.recaptchaCallback_recaptchaid = function () {
      window.grecaptcha
        .execute(recaptcha_key, { action: "homepage" })
        .then(function (token) {
          document.getElementById("recaptchaid").value = token;
        });
    };
  }
}

if (form) {
  import("pristinejs/dist/pristine").then((module) => {
    const Pristine = module.default;
    const selectHasSubField = form.querySelector(".hasSubField");

    initPristine(form, Pristine);

    elementsWithDataToShow.forEach(function (element) {
      const radioButtons = element.querySelectorAll("input[type='radio']");
      const checkboxButtons = element.querySelectorAll(
        "input[type='checkbox']"
      );
      const hiddenElementsIds = element.querySelectorAll("[data-to-show]");

      radioButtons.forEach(function (radioButton) {
        radioButton.addEventListener("click", function () {
          const targetId = radioButton.getAttribute("data-to-show");

          if (hiddenElementsIds.length) {
            hiddenElementsIds.forEach(function (hiddenElement) {
              const hiddenElementId =
                hiddenElement.getAttribute("data-to-show");

              if (document.getElementById(hiddenElementId)) {
                const requiredItem = document
                  .getElementById(hiddenElementId)
                  .querySelector(".is-required");

                document
                  .getElementById(hiddenElementId)
                  .classList.add("d-none");

                if (requiredItem) {
                  requiredItem.removeAttribute("required");
                }
              }
            });

            // Show the target element
            if (targetId) {
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                const allRequiredItems =
                  targetElement.querySelectorAll(".is-required");
                targetElement.classList.remove("d-none");
                if (allRequiredItems.length) {
                  allRequiredItems.forEach(function (requiredItem) {
                    requiredItem.setAttribute("required", "required");
                  });
                }
              }
            }

            initPristine(form, Pristine);
          }
        });
      });

      checkboxButtons.forEach(function (checkboxButton) {
        checkboxButton.addEventListener("click", function () {
          const targetId = checkboxButton.getAttribute("data-to-show");

          if (hiddenElementsIds.length) {
            hiddenElementsIds.forEach(function (hiddenElement) {
              const hiddenElementId =
                hiddenElement.getAttribute("data-to-show");

              if (document.getElementById(hiddenElementId)) {
                const targetElement = document.getElementById(targetId);
                const requiredItems = document
                  .getElementById(hiddenElementId)
                  .querySelectorAll(".is-required");
                const targetElementRequiredItems =
                  targetElement.querySelectorAll(".is-required");
                const targetElementDisplay =
                  targetElement.classList.contains("d-none");

                if (requiredItems) {
                  requiredItems.forEach(function (requiredItem) {
                    requiredItem.removeAttribute("required");
                  });
                }
                // Show the target element
                if (targetId) {
                  if (targetElementDisplay) {
                    targetElement.classList.remove("d-none");

                    if (targetElementRequiredItems) {
                      targetElementRequiredItems.forEach(
                        function (requiredItem) {
                          requiredItem.setAttribute("required", "required");
                        }
                      );
                    }
                  } else {
                    targetElement.classList.add("d-none");
                  }
                }
                initPristine(form, Pristine);
              }
            });
          }
        });
      });
    });

    // replace data to another element

    const dataToAddElement = document.querySelector("[data-to-add]");

    if (dataToAddElement) {
      const dataToAddId = dataToAddElement.getAttribute("data-to-add");
      const elementToReplace = document.getElementById(dataToAddId);

      if (elementToReplace) {
        const parentElement = dataToAddElement.parentNode;

        parentElement.appendChild(elementToReplace);
      }
    }

    const addFormDeleteLink = (item) => {
      const removeFormButton = item.querySelector(".btn-delete");

      if (removeFormButton) {
        removeFormButton.addEventListener("click", (e) => {
          const listElements = document.querySelectorAll("ul.materials li");
          const numberOfItems = listElements.length;

          e.preventDefault();
          // remove the li for the tag form
          if (numberOfItems > 1) {
            item.style.opacity = "0";
            setTimeout(function () {
              item.remove();
            }, 300);

            // item.remove();
          } else {
            // Reset the input fields within the li element
            const inputs = item.querySelectorAll("input");
            inputs.forEach(function (input) {
              input.value = "";
            });

            // Reset the file input field
            const fileInput = item.querySelector("input[type='file']");
            if (fileInput) {
              fileInput.value = "";
              // For modern browsers that support the "input" event, trigger it to clear the file input"s display
              const event = new Event("input", { bubbles: true });
              fileInput.dispatchEvent(event);
            }
          }

          const addItemLink = document.querySelector(".add_item_link");

          if (addItemLink) {
            if (numberOfItems < 6) {
              document.querySelector(
                ".add_item_link"
              ).parentElement.style.display = "inline-block";
            } else {
              document.querySelector(
                ".add_item_link"
              ).parentElement.style.display = "none";
            }
          }

          initPristine(form, Pristine);
        });
      }
    };

    document.querySelectorAll("ul.materials li").forEach((material) => {
      addFormDeleteLink(material);
    });

    document.querySelectorAll(".add_item_link").forEach((btnLink) => {
      btnLink.addEventListener("click", function (e) {
        const listElements = document.querySelectorAll("ul.materials li");
        const numberOfItems = listElements.length;
        const collectionHolder = document.querySelector(
          "." + e.currentTarget.dataset.collectionHolderClass
        );
        if (numberOfItems < 5 && numberOfItems > 0) {
          const item = document.createElement("li");

          item.innerHTML = collectionHolder.dataset.prototype.replace(
            /__name__/g,
            collectionHolder.dataset.index
          );

          collectionHolder.appendChild(item);
          addFormDeleteLink(item);

          collectionHolder.dataset.index++;

          if (numberOfItems < 4) {
            btnLink.parentElement.style.display = "inline-block";
          } else {
            btnLink.parentElement.style.display = "none";
          }
        } else {
          btnLink.parentElement.style.display = "none";
        }

        initPristine(form, Pristine);
      });
    });

    handleNumberInput();

    // close info card

    const infoCards = document.querySelectorAll(".picto-info-content");

    if (infoCards) {
      infoCards.forEach((infoCard) => {
        const closeIcon = infoCard.querySelector(".icon-close");
        const InfoContent = infoCard.querySelector(".picto-info-content-text");

        if (closeIcon && InfoContent) {
          closeIcon.addEventListener("click", function () {
            InfoContent.classList.remove("show");
          });
        }
      });
    }

    //bs custom file input

    import("bs-custom-file-input").then((module) => {
      const bsCustomFileInput = module.default;
      bsCustomFileInput.init();
    });

    // add new field when select a specifc option from select
    if (selectHasSubField) {
      const fieldToAdd = selectHasSubField.dataset.targetField;
      const selectedOption = selectHasSubField.dataset.option;

      if (fieldToAdd && selectedOption) {
        selectHasSubField.addEventListener("change", function () {
          const dataValue = this.options[this.selectedIndex].dataset.value;

          if (dataValue && dataValue == selectedOption) {
            form.querySelector(`#${fieldToAdd}`).classList.remove("d-none");
          } else {
            form.querySelector(`#${fieldToAdd}`).classList.add("d-none");
          }
        });
      }
    }
  });
}

// delete item
function deleteItem() {
  const deleteBtn = document.querySelectorAll(".delete-item-form");

  if (deleteBtn.length) {
    deleteBtn.forEach(function (item) {
      const itemToDeleteClass = item.dataset.itemToDelete;

      if (itemToDeleteClass) {
        const itemToDelete = item.closest(`.${itemToDeleteClass}`);

        item.addEventListener("click", function () {
          itemToDelete.remove();
        });
      }
    });
  }
}

// custom increment input

function handleNumberInput() {
  const incrementInput = document.querySelectorAll(".step-num-input");

  if (incrementInput.length) {
    incrementInput.forEach(function (item) {
      if (item.classList.contains("minus")) {
        item.addEventListener("click", function () {
          item.parentNode.querySelector("input[type=number]").stepDown();
        });
      }
      if (item.classList.contains("plus")) {
        item.addEventListener("click", function () {
          item.parentNode.querySelector("input[type=number]").stepUp();
        });
      }
    });
  }
}

// lazy Modal Form

const openLazyModals = document.querySelectorAll(".open-lazy-modal");

if (openLazyModals.length) {
  openLazyModals.forEach((openLazyModal) => {
    const TargetModal = document.querySelector(openLazyModal.dataset.bsTarget);

    if (TargetModal) {
      const modalContentUrl = TargetModal.dataset.contentUrl;

      TargetModal.addEventListener("show.bs.modal", function () {
        fetch(modalContentUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text();
          })
          .then((data) => {
            const modalBody = TargetModal.querySelector(".modal-body");
            if (modalBody) {
              modalBody.innerHTML = data;
              initRecaptcha();
              const formElement = modalBody.querySelector("form");
              if (formElement) {
                import("pristinejs/dist/pristine")
                  .then((module) => {
                    const Pristine = module.default;
                    if (newPristine) {
                      newPristine.destroy();
                    }
                    newPristine = new Pristine(formElement);

                    // submit form without loading page
                    formElement.addEventListener("submit", function (e) {
                      e.preventDefault();

                      const formData = new FormData(formElement);
                      newPristine.validate();

                      if (newPristine.validate()) {
                        fetch(formElement.action, {
                          method: "POST",
                          body: formData,
                          headers: {
                            "X-Requested-With": "XMLHttpRequest",
                          },
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            if (data.status === "success") {
                              import("bootstrap/js/dist/modal").then(
                                (module) => {
                                  const Modal = module.default;
                                  const hideTargetModal =
                                    Modal.getInstance(TargetModal);
                                  const successModal = new Modal(
                                    document.getElementById(
                                      "messageConfirmation"
                                    )
                                  );

                                  hideTargetModal.hide();
                                  successModal.show();
                                }
                              );
                            } else {
                              console.error(
                                "There was an error submitting the form. Please try again later."
                              );
                            }
                          })
                          .catch((error) => {
                            console.error(
                              "An unexpected error occurred. Please try again later or contact us for assistance.",
                              error
                            );
                          });
                      } else {
                        e.preventDefault();
                        console.log(newPristine.validate());
                        console.log(newPristine);

                        const hasDanger = document.querySelector(".has-danger");

                        if (hasDanger) {
                          window.scrollTo({
                            top: document.querySelector(".has-danger")
                              .offsetTop,
                            behavior: "smooth",
                          });
                        }
                      }
                    });

                    // Ajax call to remove items from storage

                    const itemsToDelete =
                      document.querySelectorAll(".delete-item-form");

                    if (itemsToDelete.length) {
                      itemsToDelete.forEach((button) => {
                        button.addEventListener("click", function () {
                          const itemId = button.dataset.itemId;
                          const storageUrl =
                            button.closest(".stored-data").dataset.storage;

                          if (itemId && storageUrl) {
                            fetch(storageUrl, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                              },
                              body: JSON.stringify({ productId: itemId }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  console.log(
                                    "Item deleted successfully from storage"
                                  );
                                } else {
                                  console.error(
                                    "Failed to delete item from storage"
                                  );
                                }
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                          }
                        });
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Pristine.js import error:", error);
                  });
              } else {
                console.error("No form element found in modal content.");
              }

              handleNumberInput();
              deleteItem();
            } else {
              console.error("Modal body element not found.");
            }
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
      });
    }
  });
}
